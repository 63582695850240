<template>

  <el-dialog @close='onClose'
             v-bind='$attrs' v-on='$listeners' @open='onOpen' title='查看设置权限'
             width='1000px'>
    <el-row :gutter="20">
      <el-col :span="12" v-loading="!listLoading">
        <el-card>
          <div slot="header" class="clearfix">
            <span>已选品牌</span>
          </div>
          <div v-if="listLoading">
          <span v-for="item in dataList" :key="item.id" style="font-size: 14px;font-weight: bold">
            {{ item.title }}
        </span>
          </div>
          <el-divider content-position="left">已选部门权限</el-divider>
          <el-tag @close="handleClose(index)" effect="plain" closable v-for="(item,index) in checkDataList"
                  :key="item.dpt_id"
                  style="margin:0 8px ">
            {{ item.name }}
          </el-tag>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card v-loading="!listLoading">
          <div slot="header" class="clearfix">
            <span>设置权限</span>
          </div>
          <div class='head-container'>
            <el-input
                v-model='deptName'
                placeholder='请输入部门名称'
                clearable
                size='small'
                prefix-icon='el-icon-search'
                style='margin-bottom: 20px'
            >
              <template slot='append'>
                <el-button type='primary' icon='el-icon-refresh' @click='getList' :loading='loading'>刷新</el-button>
              </template>
            </el-input>
          </div>
          <div v-if="show">
            <!--      <div>-->
            <!--        <el-button type='primary' icon='el-icon-position' @click='handleNodeClick([])' v-if='rangeType==="all"'>全公司</el-button>-->
            <!--      </div>-->

            <el-tree :data='options' :props='defaultProps'
                     :expand-on-click-node='false'
                     :filter-node-method='filterNode'
                     ref='deptTree'
                     show-checkbox
                     node-key="dpt_id"
                     :current-node-key="currentNodeKey"
                     :default-expand-all='false'
                     @check-change="handleCheckClick"
            ></el-tree>
          </div>
        </el-card>

      </el-col>

    </el-row>
    <el-row>
      <el-col :span="12">
        <div class='footer'>
          <el-button>取消</el-button>
          <el-button type='primary' size='small' @click="saveData">保存设置</el-button>
        </div>
      </el-col>
    </el-row>
  </el-dialog>

</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'DeptMcnPanel',
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo'])
  },
  props: {
    rangeType: {
      type: String,
      default() {
        return 'all'
      }
    },
    multipleData: {
      type: Array
    }
  },

  data() {
    return {
      loading: false,
      options: [],
      multiple: true,
      defaultProps: {
        id: 'dpt_id',
        children: 'children',
        label: 'name'
      },
      queryParams: {},
      deptName: '',
      currentNodeKey: '',
      checkDataList: [],
      dataList: [],
      listLoading: false,
      rangeData: [],
      show: true
    }
  },
  watch: {
    // 根据名称筛选部门树
    deptName(val) {
      this.$refs.deptTree.filter(val)
    },
    rangeType: {
      handler() {
        this.getList()
      }
    },
  },
  methods: {
    itemDeptName(item) {
      let data = []
      let returnData = ''
      if (item) {
        item.forEach((i) => {
          data.push(i.dept_name)
        })
        if (data.length > 0) {
          returnData = `(${String(data)})`
        } else {
          returnData = '(暂未设置权限)'
        }
      } else
        returnData = '(暂未设置权限)'


      return returnData
    },
    onOpen() {
      // this.$refs.deptTree.setCheckedKeys([])
      // getDressBrandViewRange
      this.dataList = this.multipleData
      this.checkDataList = []
      this.getRange()
    },
    async getRange() {
      this.listLoading = false

      let rangeData = []
      console.log(this.multipleData)

      for (let i = 0; i < this.dataList.length; i++) {
        let { list } = await this.$api.getDressBrandViewRange(this.dataList[i]?.id)
        this.dataList[i].range = list
        rangeData = _.concat(this.rangeData, list)
        if (i === (this.dataList.length - 1)) {
          this.listLoading = true
        }
      }

      const map = new Map()
      let checkDataList = rangeData.filter(v => !map.has(v.dept_id) && map.set(v.dept_id, v))
      let dpt_id = []
      checkDataList.forEach((item) => {
        let data = {
          name: item.dept_name,
          dpt_id: `${item.dept_id}`,
          parent_id: Number(item.dept_parent_id)
        }
        this.checkDataList.push(data)
        dpt_id.push(item.dept_id)
      })
      this.$refs.deptTree.setCheckedKeys(dpt_id)

    },

    onClose() {
      this.$emit('close')
      this.$refs.deptTree.setCheckedKeys([])

    },
    async saveData() {
      let dpt_ids = this.checkDataList.map((item) => {
        return item.dpt_id
      })
      let selectIds = this.multipleData.map((item) => {
        return item.id
      })
      let id = await this.$api.setDressBrandViewRange(selectIds, dpt_ids)
      if (id)
        this.$message.success('保存成功')
      this.$emit('close')
    },
    handleClose(index) {
      this.checkDataList.splice(index, 1)
      let ids = this.checkDataList.map((i) => {
        return i.dpt_id
      })
      this.$refs.deptTree.setCheckedKeys(ids)
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true
      return data[this.defaultProps.label].indexOf(value) !== -1
    },
    // 勾选状态
    handleCheckClick(data) {
      this.$emit('handleSelect', data)
      this.checkDataList = this.$refs.deptTree.getCheckedNodes()
      //   setCheckedKeys
    },
    async getList() {
      this.loading = true
      // let { list } = await this.$api.getMcnGroupDepts()
      let { list } = await this.$api.getDepartmentAll()
      // let { list } = await this.$api.getMcnGroupDeptOption()
      this.loading = false
      this.data = list
      this.calcOptions()
    },
    calcOptions() {
      if (this.rangeType === 'dpt') {
        let dpt_range = this.userDptInfo.dpt_range
        if (dpt_range) {
          this.data = this.data.filter((item) => {
            return dpt_range.indexOf(item.dpt_id) > -1
          })
        } else {
          this.data = []
        }
      } else if (this.rangeType === 'group') {
        let my_dpt_range = this.userDptInfo.my_dpt_range
        if (my_dpt_range) {
          this.data = this.data.filter((item) => {
            return my_dpt_range.indexOf(item.dpt_id) > -1
          })
        } else {
          this.data = []
        }
      }

      this.options = this.handleTree(this.data, 'dpt_id', 'parent_id', 'children', '1')

      if (this.options.length > 0) {
        let dept = this.options[0]
        //计算第一个项目组
        this.calcLastDept(dept)
      }

    },
    calcLastDept(dept) {
      if (dept && dept['children']) {
        let firstChildDept = dept['children'][0]
        if (firstChildDept) {
          this.calcLastDept(firstChildDept)
        }
      } else {
        if (!this.multiple) {
          //单选
          this.value = dept.dpt_id || null
          this.$emit('handleSelect', [this.value])
        }
      }
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped>

</style>
