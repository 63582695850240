<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'
               width='90%'>
      <el-row :gutter='15'>
        <el-col :span='12'>
          <el-upload
            class='upload-demo'
            :on-change='handleChange'
            :on-success='importSuccess'
            :headers='uploadHeaders'
            :data='postData'
            name='file'
            :show-file-list='false'
            :file-list='file'
            :before-upload='beforeUpload'
            drag
            action='/admin_api/dress_brand/import'
            multiple>
            <i class='el-icon-upload'></i>
            <div class='el-upload__text'>将文件拖到此处，或<em>点击上传</em></div>
            <div class='el-upload__tip' slot='tip'></div>
          </el-upload>
        </el-col>
        <el-col :span='12'>
          <div>
            <export-btn :can-export='true' ref='refExportLink' type='primary' lintTitle='下载导入模板'
                        @export='downloadTpl'></export-btn>
          </div>
          <div>
            <tips :data='tipList'></tips>
          </div>

        </el-col>
      </el-row>
      <el-row>
        <el-col :span='24'>
<!--          <div style='margin-bottom: 5px'>-->
<!--            <span style='font-size: 1.3em;font-weight: bold'>导入数据预览</span>-->
<!--            <el-link type='primary' @click='showDemo' style='float: right'>查看示例</el-link>-->
<!--          </div>-->
          <div class='default-table'>
            <el-divider>导入数据预览</el-divider>
            <el-table :data='demandList' max-height='500' border>
              <el-table-column label='序号' type='index' align='center' width='60'></el-table-column>
              <el-table-column label='品牌' prop='title' align='center' min-width='100'></el-table-column>
              <el-table-column label='类目' prop='category' align='center' min-width='100'></el-table-column>
              <el-table-column label='风格' prop='brand_style' align='center' min-width='100'></el-table-column>
              <el-table-column label='适合达人' prop='kol_names' align='center' min-width='100'></el-table-column>
              <el-table-column label='链接' prop='link' align='center' min-width='100'></el-table-column>
              <el-table-column label='可播平台' prop='platform_names' align='center' min-width='100'></el-table-column>
              <el-table-column label='合同情况' prop='sign_status' align='center' min-width='100'></el-table-column>
              <el-table-column label='商务评价' prop='evaluation' align='center' min-width='100'></el-table-column>
              <el-table-column label='备注' prop='remark' align='center' min-width='200'></el-table-column>
              <el-table-column label='初始对接商务' prop='org_bc' align='center' min-width='100'></el-table-column>
              <el-table-column label='录入时间' prop='enter_at' align='center' min-width='120'></el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import ExportBtn from '@/components/export/ExportBtn'
import { getToken } from '@/utils/auth'

export default {
  name: 'ImportDressBrand',
  components: { ExportBtn },
  props: {
    id: {
      type: [Number, String]
    }
  },
  data() {
    return {
      dialogTitle: '导入',
      file: [],
      uploadLoading: false,
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      postData: { lb_id: null },
      maxSize: 10,//文件最大的 5 M
      tipList: [
        '第1步，下载模板',
        '第2步，按照模板规范填好数据后，根据提示上传文件',
        '注意：1）二次导入，将会追加数据 2）表格空行会过滤'
        // '第3步，预览导入的数据，确认无误后，点击“提交”按钮'
      ],
      demandList: [
        // {
        //   category_name: '美妆—护肤',
        //   demand: '秋冬保湿补水面霜',
        //   // price_first: '100-200',
        //   // num_first: '5',
        //   // price_second: '200-500',
        //   // num_second: '3',
        //   // price_third: '500-1000',
        //   // num_third: '2',
        //   title: '1.绽妍  2.颐莲 3.PMPM',
        //   remark: '请写清楚具体产品及价格范围'
        // }
      ]
    }
  },
  methods: {
    onOpen() {
      // this.$notify.info('排期ID：' + this.id)
      this.demandList = []
      // this.showDemo()
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },
    showDemo() {
      this.demandList = []
    },
    async downloadTpl() {
      try {
        let name = `导入模板`
        this.$notify.info(name)
        let queryParams = {
          is_tpl: true
        }
        let response = await this.$api.exportDressBrand(queryParams)
        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    // ======================= 导入相关 =====================
    handleChange(file) {
      this.file = [file]
    },
    beforeUpload(file) {
      //身份凭据
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
      // this.postData['lb_id'] = this.id

      this.uploadLoading = true
      const isLt2M = file.size / 1024 / 1024 < this.maxSize
      if (!isLt2M) {
        this.$message.error(`导入文件大小不能超过 ${this.maxSize}MB!`)
        this.uploadLoading = false
      }
      return isLt2M
    },
    importSuccess(response) {
      if (response.code === 200) {
        this.$notify.success('导入成功')
        let sheet_data  = response.data ? response.data.list : []
        this.demandList = sheet_data
        this.$emit('imported')
        // setTimeout(() => {
        //   this.close()
        // }, 500)
      } else {
        this.$message.warning(response.message)
      }
    }
  }
}
</script>

<style scoped>

</style>