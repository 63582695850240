<template>
  <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen'
             :title="`服饰库-${custom_type==='add'?'添加':'修改'}品牌信息`"
             :before-close='beforeClose' center>
    <el-form ref='formData' :model='formData' :rules='rules' label-width='110px' size='medium'>
      <el-row>
        <el-col :span='12'>
          <el-form-item label='品牌' prop='title'>
            <el-input v-model='formData.title' clearable placeholder='请输入品牌'></el-input>
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='类目'>
            <el-select
                v-model="formData.category"
                filterable
                allow-create
                default-first-option
                placeholder="请选择类目">
              <el-option
                  v-for="item in categoryOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <!--            <el-input v-model='formData.category' clearable placeholder='请输入类目'></el-input>-->
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='风格'>
            <el-input v-model='formData.brand_style' clearable placeholder='请输入风格'></el-input>
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='客单价带'>
            <el-select
                v-model="formData.price_band"
                filterable
                allow-create
                default-first-option
                placeholder="请选择客单价带">
              <el-option
                  v-for="item in priceBandOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <!--            <el-input v-model='formData.price_band' clearable placeholder='请输入客单价带'></el-input>-->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span='12'>
          <el-form-item label='适合达人'>
            <el-input v-model='formData.kol_names' clearable placeholder='请输入适合达人'></el-input>
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='可播平台'>
            <el-select
                v-model="formData.platform_names"
                filterable
                allow-create
                default-first-option
                placeholder="请选择可播平台">
              <el-option
                  v-for="item in platformOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <!--            <el-input v-model='formData.platform_names' clearable placeholder='请输入可播平台'></el-input>-->
            <!--            <PlatformSelect @saved='handleSelectPlatform' :range-all='true' v-model='formData.platform_code'-->
            <!--                        style="width: 220px"    placeholder='请选择可播平台' :default-code='formData.platform_code' />-->
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label='链接'>
        <el-input v-model='formData.link' maxlength='500' show-word-limit clearable placeholder='请输入链接,链接使用【http://、https://、//】作为开头'></el-input>
      </el-form-item>
      <el-form-item label='合同情况'>
        <el-select
            v-model="formData.sign_status"
            filterable
            allow-create
            default-first-option
            placeholder="请选择合同情况">
          <el-option
              v-for="item in signOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <!--        <el-input v-model='formData.sign_status' maxlength='50' show-word-limit clearable placeholder='请输入合同情况'></el-input>-->
      </el-form-item>
<!--      <el-form-item label='是否可签合同'>-->
<!--        <el-radio-group v-model='formData.is_sign' size='medium'>-->
<!--          <el-radio v-for='item in isSigningOptions' :key='item.value'-->
<!--                    :label='item.value'-->
<!--                    border>-->
<!--            {{item.label}}-->
<!--          </el-radio>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->
      <el-form-item label='商务评价'>
        <el-select
            v-model="formData.evaluation"
            filterable
            allow-create
            default-first-option
            placeholder="请选择商务评价">
          <el-option
              v-for="item in evaluationOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <!--        <el-input v-model='formData.evaluation' clearable maxlength='100' show-word-limit placeholder='请输入商务评价'></el-input>-->
      </el-form-item>

      <el-form-item label='备注' prop='remark'>
        <el-input v-model='formData.remark' clearable maxlength='500' show-word-limit placeholder='请输入备注信息'
                  rows='5'
                  type='textarea'></el-input>
      </el-form-item>
      <el-form-item label='初始对接商务'>
        <el-input v-model='formData.org_bc' clearable maxlength='100' show-word-limit placeholder='请输入初始对接商务'></el-input>
      </el-form-item>
      <el-form-item label='录入时间'>
        <el-date-picker
          v-model="formData.enter_at"
          type="date"
          value-format='yyyy-MM-dd'
          format='yyyy年MM月dd日'
          placeholder="选择录入时间">
        </el-date-picker>

      </el-form-item>
    </el-form>
    <span slot='footer' class='dialog-footer'>
        <el-button @click='beforeClose'>取 消</el-button>
        <el-button type='primary' @click='handelStore'>确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import ApeUploader from '@/components/ApeUploader'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import ArtistSearch from '@/components/artist/ArtistSearch'
import PlatformSelect from '@/pages/lb/components/PlatformSelect'

const rules = {
  title: [
    { required: true, message: '请输入品牌名', trigger: 'blur' },
    { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
  ]
}
export default {
  name: 'AddDress',
  components: {
    ApeUploader, CategoryCascader, ArtistSearch, PlatformSelect
  },
  data() {
    return {
      rules: rules,
      uploadFileList: [],
      selectDress: false,
      formData: {},
      isSigningOptions: [
        { value: 'Y', label: '可签' },
        { value: 'N', label: '不可签' }
      ],
      categoryOptions: [
        { value: '衣服', label: '衣服' },
        { value: '箱包', label: '箱包' },
        { value: '鞋靴', label: '鞋靴' },
        { value: '配饰', label: '配饰' },
        { value: '运动户外', label: '运动户外' },
        { value: '家居家纺', label: '家居家纺' },
        { value: '数码电器', label: '数码电器' },
        { value: '其他', label: '其他' }
      ],
      priceBandOptions: [
        { value: '300以内', label: '300以内' },
        { value: '300-500', label: '300-500' },
        { value: '500-1000', label: '500-1000' },
        { value: '1000以上', label: '1000以上' }
      ],
      platformOptions: [
        { value: '抖音', label: '抖音' },
        { value: '小红书', label: '小红书' },
        { value: '抖音和小红书', label: '抖音和小红书' }
      ],
      signOptions: [
        { value: '可签合同', label: '可签合同' },
        { value: '已签全体合同', label: '已签全体合同' },
        { value: '不签合同，保价退差', label: '不签合同，保价退差' },
        { value: '不前合同，不保价', label: '不前合同，不保价' }
      ],
      evaluationOptions: [
        { value: '好看', label: '好看' },
        { value: '还可以', label: '还可以' },
        { value: '一般', label: '一般' },
        { value: '不好看', label: '不好看' }

      ]
    }
  },
  props: {
    formStore: {
      type: Object
    },
    custom_type: {
      type: String
    }
  },
  watch: {
    formStore(val) {
      this.formData = val

    }
  }
  ,
  methods: {
    // 级联菜单组件change事件
    cascaderChange(v) {
      this.formData.category_id = null
      this.formData.category_2_id = null
      this.formData.category = null
      if (v?.length) {
        // this.formData.category_id = v[(v.length - 1)]
        // this.formData.category_ids = v
        this.formData.category_id = v[0]
        const categoryNode = this.$refs['refCategory'].getNode(this.formData.category_id)
        this.formData.category = categoryNode.display_name || ''
        if (v.length === 2) {
          // this.formData.category_id = v[0]
          this.formData.category_2_id = v[1]
          // const category2Node = this.$refs['refCategory'].getNode(this.formData.category_2_id)
          const category2Node = categoryNode.children.find(value => value.id === this.formData.category_2_id)
          if (category2Node)
            this.formData.category += ' / ' + category2Node.display_name || ''
        }
      }
      //类目名称：类目/类目2
      // this.handleQuery()
    },
    onOpen() {
      this.formData = this.formStore
      this.uploadFileList = this.formData.cover_url ? [{ url: this.formData.cover_url }] : []
    },
    //关闭弹窗
    beforeClose() {
      this.$emit('close')
      this.uploadFileList = []
    }
    ,
    handelStore() {
      this.$emit('handelStore', this.formData)
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.formData.validate(async (valid) => {
          if (valid) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    selectArtist(row) {
      this.formData.artist_id = row.id || ''
      this.formData.nickname = row.nickname || ''
    },
    handleSelectPlatform(val) {
      this.formData.platform_code = val ? val.code : []
      this.formData.platform_name = val ? val.name : []
    }
  }
}
</script>

<style scoped>

</style>
