<template>
  <div>
    <div class="radius_bg">
      <el-row :gutter='15'>
        <el-col :span='24'>
          <el-form size='mini' inline>
            <el-form-item>
              <el-input v-model='searchCondition.title' placeholder='请输入品牌' clearable @change='getList'></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model='searchCondition.category' placeholder='请输入类目' clearable
                        @change='getList'></el-input>
              <!--              <CategoryCascader :identify='`dress`' @cascaderChange='cascaderChange' />-->
            </el-form-item>
            <el-form-item>
              <el-input v-model='searchCondition.kol_names' placeholder='请输入达人名称' clearable
                        @change='getList'></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model='searchCondition.platform_names' placeholder='请输入平台' clearable
                        @change='getList'></el-input>
              <!--              <PlatformSelect :range-all='true' :purpose='`is_data_entry`'-->
              <!--                              :placeholder="'请选择可播平台'" @change='changePlatform' clearable />-->
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' @click='getList' :disabled='tableLoading'
                         :loading='tableLoading'>查询
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click='reset'>重置</el-button>
            </el-form-item>
            <el-form-item style='float: right'>
              <el-tag type='success' effect='plain' v-if='userPermissions.indexOf("dress_brand_all")>-1'
                      style='float: right'>
                查看全部
              </el-tag>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row :gutter='5'>
        <el-col :span='1.5'>
          <el-button icon='el-icon-plus' type='primary' @click="handleDressBrand('add')"
                     v-if='userPermissions.indexOf("dress_brand_create")>-1'>新增
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <el-button icon='el-icon-delete' type='danger' @click='handleDelete()'
                     v-if='userPermissions.indexOf("dress_brand_delete")>-1'>批量删除
          </el-button>
        </el-col>
        <!--        <el-col :span='1.5'>-->
        <!--          <el-button v-if='userPermissions.indexOf("dress_brand_export")>-1' v-loading='downLoading'-->
        <!--                     @click='exportDownload'>-->
        <!--            <i :class="downLoading?'el-icon-loading':'el-icon-download'">导出</i>-->
        <!--          </el-button>-->
        <!--        </el-col>-->
        <!--        <el-col :span='1.5'>-->
        <!--          <div class='download' v-if='userPermissions.indexOf("dress_brand_import")>-1'>-->
        <!--            <el-upload-->
        <!--              ref='upload'-->
        <!--              :auto-upload='false'-->
        <!--              :before-upload='beforeUpload'-->
        <!--              :data='{http_id:httpId}'-->
        <!--              :disabled='loadingUpload'-->
        <!--              :file-list='file'-->
        <!--              :headers='uploadHeaders'-->
        <!--              :on-change='handleChange'-->
        <!--              :on-success='importSuccess'-->
        <!--              :show-file-list='false'-->
        <!--              action='/admin_api/kol_info/import'-->
        <!--              class='upload-demo'-->
        <!--              multiple-->
        <!--            >-->
        <!--              <el-button :icon="`${loadingUpload?'el-icon-loading':'el-icon-upload2'}`"-->
        <!--                         :loading='loadingUpload'-->
        <!--                         size='mini'>批量导入-->
        <!--              </el-button>-->
        <!--            </el-upload>-->
        <!--          </div>-->
        <!--        </el-col>-->
        <el-col :span='1.5'>
          <export-btn :can-export='userPermissions.indexOf("dress_brand_export")>-1' :lint-title='`导出表格`'
                      @export='exportExcel' ref='refExportLink'></export-btn>
        </el-col>
        <el-col :span='1.5'>
          <el-button icon='el-icon-upload' @click='openImport=true'
                     v-if='userPermissions.indexOf("dress_brand_import")>-1'>导入
          </el-button>
        </el-col>

        <el-col :span='1.5'>
          <el-button icon='el-icon-setting' v-if='userPermissions.indexOf("dress_brand_set_view")>-1'
                     @click='handlSetting("all")'>设置查看权限
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <el-button icon='el-icon-setting' @click='customColumnShow=true'>列设置</el-button>
        </el-col>
      </el-row>
      <br/>
    </div>
    <div class='default-table radius_bg'>
      <MSetTable ref='setTableRef' :showIndex='true' :offset='offset' max-height='800' :tableData='dataList'
                 :columns='columns'
                 :loading='tableLoading'
                 :cell-class-name='addCellClass'
                 :border='true'
                 row-key='id'
                 @row-dblclick='dblclickRow'
                 @selection-change='handleSelectionChange'
      >
        <el-table-column type='selection' width='55' align='center' slot='first-column'></el-table-column>
        <el-table-column align='center' label='操作' width='120' fixed='right'>
          <template slot-scope='{row}'>
            <el-button type='text' v-if='userPermissions.indexOf("dress_brand_edit")>-1'
                       @click="handleDressBrand('edit',row)">修改<i class='el-icon-edit-outline'></i>
            </el-button>
            &nbsp;
            <el-dropdown>
              <span class='el-dropdown-link'>
                更多<i class='el-icon-arrow-down el-icon--right'></i>
              </span>
                <el-dropdown-menu slot='dropdown'>
                  <el-dropdown-item>
                    <el-button icon='el-icon-setting' v-if='userPermissions.indexOf("dress_brand_set_view")>-1'
                               type='text' @click='handlSetting("only",row)'>设置查看权限
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button icon='el-icon-document-copy' v-if='userPermissions.indexOf("dress_brand_create")>-1'
                               type='text' @click="handleDressBrand('copy',row)">复制
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item divided>
                    <el-button icon='el-icon-delete' type='text' v-if='userPermissions.indexOf("dress_brand_delete")>-1'
                               @click='handleDelete(row)'>删除
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </MSetTable>
      <div>

        <!--    渲染分页-->
        <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                      @pagination='getList'/>
      </div>
      <div v-if='userPermissions.indexOf("dress_brand_log")>-1'>
        <el-divider/>
        <el-link style="margin: 10px" type='primary' @click='showLog=!showLog'>日志
          <span :class='[!showLog?"el-icon-arrow-down":"el-icon-arrow-up"]'></span>
        </el-link>
      </div>
      <!--    列设置-->
      <MColumnSetting :visible.sync='customColumnShow' ref='columnSetting' @userChange='columnSettingChange'
                      @save='columnSettingSave' @back='columnSettingBack' :column='userColumns'
                      :table-name='tableName'/>
    </div>


    <DressBrandLog v-if='showLog'/>

    <!--    设置查看权限-->
    <MultipleDeptSelect :visible.sync='open' @close='closeOpen' :multipleData='multipleData'
                        @handleSelect='selectDept'/>

    <!--    添加组件-->
    <AddDressBrand ref='addDress' :custom_type='custom_type' :formStore.sync='formStore' :visible.sync='dialogStore'
                   @close='closeAdd' @handelStore='handelStore'></AddDressBrand>
    <!--    批量导出-->
    <exportDialog :file-name='`服饰品牌库`' :column='userColumns'
                  :searchCondition='searchCondition' :titleColor='titleColor' :visible.sync='downloadLoading'
                  @close='downloadLoading=false'></exportDialog>

    <ImportDressBrand :visible.sync='openImport' @imported='handleQuery' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import ApeUploader from '@/components/ApeUploader'
import MColumnSetting from '@/components/common/MColumnSetting'
import MSetTable from '@/components/common/MSetTable'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import PlatformSelect from '@/pages/platform/components/PlatformSelect'
import AddDress from '@/pages/lb/dress/AddDress.vue'
import AddDressBrand from '@/pages/lb/dress/AddDressBrand.vue'
import _ from 'lodash'
import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect'
import { getToken } from '@/utils/auth'
import exportDialog from '@/pages/lb/dress/exportDialog.vue'
import MultipleDeptSelect from '@/pages/lb/dress/MultipleDeptSelect'
import ImportDressBrand from '@/pages/lb/dress/components/ImportDressBrand'
import DressBrandLog from '@/pages/lb/dress/components/DressBrandLog'

export default {
  name: 'investment',
  data() {
    return {
      dataList: [],
      total: 0,
      pagingData: {
        page_size: 10,
        current_page: 1
      },
      orderSort: { id: 'desc' },
      userColumns: [
        // { hide: false, prop: 'id', label: '序号', width: 80, sortable: false, fixed: false ,type:'index'},
        // {
        //   hide: true,
        //   prop: 'id',
        //   label: 'ID',
        //   width: 80,
        //   sortable: false,
        //   fixed: false,
        //   wch: 20,
        //   hpx: 30,
        //   overflow: true
        // },
        {
          hide: false,
          prop: 'title',
          label: '品牌',
          width: 100,
          sortable: false,
          fixed: false,
          wch: 20,
          overflow: true
        },
        {
          hide: false,
          prop: 'category',
          label: '类目',
          width: 80,
          sortable: false,
          fixed: false,
          wch: 20,
          overflow: true
        },
        {
          hide: false,
          prop: 'brand_style',
          label: '风格',
          width: 80,
          sortable: false,
          fixed: false,
          wch: 20,
          overflow: true
        },
        {
          hide: false,
          prop: 'price_band',
          label: '客单价带',
          width: 120,
          sortable: false,
          fixed: false,
          wch: 20,
          overflow: true
        },
        {
          hide: false,
          prop: 'kol_names',
          label: '适合达人',
          width: 100,
          sortable: false,
          fixed: false,
          wch: 20,
          overflow: true
        },
        {
          hide: false,
          prop: 'link',
          label: '链接',
          width: 180,
          sortable: false,
          fixed: false,
          wch: 20,
          overflow: true
        },
        {
          hide: false,
          prop: 'platform_names',
          label: '可播平台',
          width: 100,
          sortable: false,
          fixed: false,
          wch: 20,
          overflow: true
        },
        {
          hide: false,
          prop: 'sign_status',
          label: '合同情况',
          width: 110,
          sortable: false,
          fixed: false,
          wch: 20,
          overflow: true
        },
        {
          hide: false,
          prop: 'evaluation',
          label: '商务评价',
          width: 120,
          sortable: false,
          fixed: false,
          wch: 20,
          overflow: true
        },
        {
          hide: false,
          prop: 'remark',
          label: '备注',
          width: 120,
          sortable: false,
          fixed: false,
          wch: 20,
          overflow: true
        },
        {
          hide: false,
          prop: 'org_bc',
          label: '初始对接商务',
          width: 120,
          sortable: false,
          fixed: false,
          wch: 20,
          overflow: true
        },
        {
          hide: false,
          prop: 'enter_at',
          label: '录入时间',
          width: 120,
          sortable: false,
          fixed: false,
          wch: 20,
          overflow: true
        }
        // {
        //   hide: true,
        //   prop: 'updated_at',
        //   label: '修改时间',
        //   width: 220,
        //   sortable: false,
        //   fixed: false,
        //   wch: 30,
        //   overflow: true
        // }
      ],
      columns: [],
      tableLoading: false,
      //导入
      openImport: false,
      offset: 0,
      customColumnShow: false,
      tableName: 'dress_table',
      searchCondition: { title: null, nickname: null, platform_code: null, category_id: null, category_ids: null },
      dialogStore: false,
      formStore: {},
      downLoading: false,
      custom_type: 'add',
      open: false,
      showLog: false,//显示隐藏日志
      loadingUpload: false,
      file: [],
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      httpId: '',
      downloadLoading: false,
      ids: [],
      multiple: true,
      multipleData: [],
      titleColor: {
        fgColor: '1296db',
        fontColor: 'ffffff',
        fontSize: 20
      }
    }
  },

  components: {
    DressBrandLog,
    ImportDressBrand,
    MultipleDeptSelect,
    AddDress,
    ExportBtn, ApeUploader, MColumnSetting, MSetTable, CategoryCascader, PlatformSelect, AddDressBrand,
    EcpDeptSelect, exportDialog

  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  methods: {
    closeAdd() {
      this.formStore = {}
      this.dialogStore = false
    },
    closeOpen() {
      this.open = false
    },
    handlSetting(type, row) {
      if (type === 'all') {
        if (this.ids?.length > 0) {
          this.open = true
        } else this.$message.error('请至少勾选一条数据')
      } else {
        if (row) {
          this.$refs.setTableRef.clearSelection()

          this.multipleData = [row]
          this.open = true

        }
      }

    },
    handleDressBrand(type, row) {
      this.dialogStore = true
      this.custom_type = type
      // type === 'edit' ? this.formStore = row : ''

      switch (type) {
        case 'edit':
          this.formStore = row
          break
        case 'copy':
          delete row.id
          this.formStore = row
          break
      }
    },
    // eslint-disable-next-line no-unused-vars
    addCellClass({ row, column }) {
      if (column.property === 'title') {
        return 'cell-link'
      }
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
      this.multipleData = selection
    },
    columnSettingChange(userColumn) {
      //设置列-修改表显示
      // this.customColumnShow=false
      this.columns = [...userColumn]
      this.isColSet = true
    },
    // eslint-disable-next-line no-unused-vars
    columnSettingSave() {
      this.customColumnShow = false
      this.isColSet = true
    },
    columnSettingBack() {
      this.customColumnShow = false
      this.isColSet = false
      this.getDefaultColumns()
    },
    getDefaultColumns() {
      if (localStorage.getItem(`table-column:${this.tableName}`)) {
        const tableSet = JSON.parse(localStorage.getItem(`table-column:${this.tableName}`))
        let userColumn = tableSet.user_columns
        this.columns = [...userColumn]
        this.isColSet = true
      } else {
        this.isColSet = false
        this.columns = [...this.userColumns]
      }
    },
    // 级联菜单组件change事件
    cascaderChange(v) {
      this.searchCondition.category_id = null
      this.searchCondition.category_ids = null
      if (v.length) {
        this.searchCondition.category_id = v[(v.length - 1)]
        this.searchCondition.category_ids = v
      }
      this.getList()
    },
    changePlatform(val) {
      this.searchCondition.platform_code = val
    },
    handleSearchCondition() {
      let cond = {}
      if (this.searchCondition.title) {
        cond['title'] = this.searchCondition.title
      }
      if (this.searchCondition.category) {
        cond['category'] = this.searchCondition.category
      }
      if (this.searchCondition.kol_names) {
        cond['kol_names'] = this.searchCondition.kol_names
      }
      if (this.searchCondition.platform_names) {
        cond['platform_names'] = this.searchCondition.platform_names
      }
      // if (this.searchCondition.category_id) {
      //   cond['category_id'] = this.searchCondition.category_id
      // }
      // if (this.searchCondition.category_ids) {
      //   cond['category_ids'] = this.searchCondition.category_ids
      // }
      return cond
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()
    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },
    async getList() {
      this.dataList = []
      this.tableLoading = true
      //获取当前全部选品
      let params = this.handleSearchCondition()
      //列表全部加载，前端排序即可
      Object.assign(params, this.pagingData, { order: this.orderSort })
      let { list, pages } = await this.$api.getDressBrandList(params)
      this.dataList = list
      this.pagingData.current_page = pages.current_page || 1
      this.pagingData.page_size = pages.page_size || 1
      this.total = pages.total
      this.tableLoading = false
    },
    reset() {
      this.searchCondition = {}
      this.getList()
    },
    dblclickRow(row, column, event) {
      event.preventDefault()
      if (column.property === 'title') {
        let isHttp = row.link.slice(0, 4) === 'http'
        let isSlash = row.link.slice(0, 2) === '//'
        let isHref = isHttp || isSlash
        if (isHref) {
          window.open(row.link)
        } else this.$message.error('请输入正确的链接，链接使用【http://、https://、//】作为开头')
      }

    },

    async saveData(form) {
      let id = await this.$api.saveDressBrandInfo(form)
      if (id) {
        await this.getList()
      }
    },
    //新增-修改保存按钮
    handelStore(val) {
      let _t = this
      this.$refs.addDress.validate().then(() => {
        if (this.custom_type === 'add') {
          //这里是新增的逻辑
          this.dataList.push(val)
          this.saveData(val)
          this.$message.success('新增成功')
        } else {
          //这里是修改的逻辑
          let _index = _.findIndex(this.dataList, function(o) {
            return _t.formStore.id == o.id
          })
          this.dataList[_index] = val
          this.saveData(val)
          this.$message.success('修改成功')
        }
        this.dialogStore = false
        this.formStore = {}

      }).catch(() => {
        this.$message.error('请检查必填信息')
      })
    },
    exportDownload() {
      this.downloadLoading = true
    },
    //导出排期选品
    async exportExcel() {
      if (this.isColSet) {
        //若列已设置，则直接下载
        try {
          let name = `服饰品牌库`
          let searchCondition = this.handleSearchCondition()
          Object.assign(searchCondition, { table_name: this.tableName })
          let response = await this.$api.exportDressBrand(searchCondition)
          this.$refs.refExportLink.exportSaveXlsx(response, name)
        } catch (e) {
          this.$message.warning('导出异常，请联系管理员')
        }
      } else {
        //hideLoading
        if (this.$refs.refExportLink)
          this.$refs.refExportLink.hideLoading()
        this.$confirm(`导出表格的列未设置，先设置列?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.customColumnShow = true
        }).catch(() => {
          this.$message.info('已取消')
          // this.close()
          return false
        })
      }
    },
    async delInfo(id) {
      let info = await this.$api.deleteDressBrandInfo(id)
      if (info) {
        // await this.getList()
        this.msgSuccess('删除成功')
        this.getList()
      }
    },
    handleDelete(row) {
      if (row) {
        let _t = this
        this.$confirm(`是否确认删除品牌【${row.title}】?`, '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let _index = _.findIndex(this.dataList, function(o) {
            return _t.formStore.id == o.id
          })
          this.dataList.splice(_index, 1)
          //网络请求：
          this.delInfo(row.id)

        }).catch(() => {
          this.msgWarn('取消操作')
        })
      } else {
        if (this.ids?.length > 0) {
          this.$confirm(`是否确认批量删除【${this.ids.length}】个品牌吗?`, '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            // this.$message.success('网络请求数据')
            //网络请求：
            // this.delInfo(this.ids)
            this.ids.forEach((i) => {
              this.delInfo(i)
            })
            this.getList()


          }).catch(() => {
            this.msgWarn('取消操作')
          })
        } else {
          this.$message.error('请选择要删除的数据')
        }

      }

    },
    async selectDept(arr) {
      //项目部2-组3
      let formData = {}
      if (arr && arr?.length === 3) {
        formData.dept_id = arr[arr.length - 2].dpt_id
        formData.group_id = arr[arr.length - 1].dpt_id
        formData.dept_name = arr[arr.length - 2].name
        formData.group_name = arr[arr.length - 1].name
      }

      // this.open = false

    },
    beforeUpload() {
      this.httpId = Math.ceil(Math.random() * 999999999999)
      this.percentage = 0
      this.loadingUpload = true
      this.loadingStatus = true
      this.uploadFileShow = true
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
    },

    handleChange(file) {
      this.file = [file]
      this.fileName = this.file[0].name
      this.ws()
      this.wsStop = true
      this.progressShow = true

    },
    importSuccess(response) {
      this.loadingUpload = false
      this.loadingStatus = false
      if (response.code === 200) {
        this.$notify.success(response.data)
        this.submitted = []
        this.showProgress = false
        this.reloadData = true

        this.dataList = response.data.list || []
        this.getList()
      } else {
        this.$notify.warning(response.msg)
      }
    },
    beforeClose() {
      this.open = false
    }
  },
  mounted() {
    this.getDefaultColumns()
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
@import "@/pages/lb/style/main.scss";

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: 50%;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

::v-deep .avatar {
  width: 178px;
  height: 178px;
  display: block;
}


::v-deep.list-table .el-table thead {
    background: #eee;
    color: #666;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

::v-deep.list-table .el-table__header-wrapper .el-table__header thead tr th {
    background: #EBEEF5;
    padding: 0 0 !important;
}

::v-deep.list-table .el-table__fixed-header-wrapper .el-table__header thead tr th {
    background: rgba(248, 248, 248, 1);
    padding: 0 0 !important;
}

::v-deep.list-table th, .list-table td {
    /*padding: 4px 0;*/

}

::v-deep.list-table div.el-table td .cell {
    height: auto !important;
    line-height: 20px;
    font-weight: normal;
    font-size: 12px;

}

/*el-table__cell*/
::v-deep.list-table thead th .cell {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 800;
    color: #0C0C0C;
}

::v-deep.list-table-child thead tr th .cell {
    height: 30px !important;
    line-height: 30px !important;
    font-size: 12px !important;
    /*font-weight: 800 !important; !**!*/
    color: #0C0C0C !important;
}

::v-deep.list-table thead th.is-leaf .cell {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 800;
    color: #0C0C0C;
}


::v-deep.list-table-child thead th.is-leaf .cell {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    font-weight: normal !important;
    color: #0C0C0C;

}

::v-deep.list-table-child .el-table__header-wrapper .el-table__header thead tr th {
    background: #fbf2f5;
    padding: 0 0 !important;
}

::v-deep.list-table .el-table thead tr, .list-table .el-table thead th {
    background: rgb(255, 255, 255) !important;
    padding: 10px 0 !important;
    color: rgba(50, 50, 50, 1);
    font-size: 14px;
    font-weight: normal;

}

::v-deep.list-table .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #EBEEF5;
}

.update {
    display: flex;
    float: left;
    margin-right: 10px;
}
</style>
<style>
.cell-link {
    /*text-underline-offset: -10px;*/
    /*text-decoration-thickness: 10px;*/
    text-decoration-color: #ff3176;
    text-underline-position: under;
    text-decoration-line: underline;
}

.cell-link:hover {
    cursor: pointer;
}
</style>
